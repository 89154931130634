<template>
  <v-app>
    <v-container fluid class="fill-height" id="login-bg">
      <v-row align="center" justify="center">
        <v-col cols="11" sm="8" md="6" lg="5">
          <v-card class="elevation-12">
              <v-card-title>
                  <h2>Opps!</h2>
              </v-card-title>
              <v-card-title>
                  <h3>Página no econtrada :|</h3>
              </v-card-title>
              <v-divider class="mx-3"></v-divider>
              <v-card-text>
                  La página que intenta acceder no existe o ha sido removida.
              </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>

export default {
  name: "page404",
  
  data() {
    return {
      loading: false,
    };
  },
  created() {
    
  },
  methods: {
    
  },
};
</script>

<style>
.flat-link {
    text-decoration: none;
}
#login-bg {
  height: 100vh;
  width: 100vw;
  background-color: #0d47a1;
  background-image: linear-gradient(10deg, #81d4fa 50%, transparent 50%),
    linear-gradient(-60deg, #5e35b1 30%, transparent 30%);
}
</style>
